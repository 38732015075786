<template>
  <v-container
    id="google-maps"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-google-maps
      </v-icon> Mark Project Gate
    </h2>
    <br>
    <input
      id="pac-input"
      v-model="searchText"
      class="controls"
      type="text"
      placeholder="Search Location"
    ><v-icon
      v-if="isTextEntered"
      size="25"
      @click="clear()"
    >
      mdi-close
    </v-icon>
    <v-btn
      id="button-1"
      :disabled="!isTextEntered"
      class="mt-2"
      color="#000"
      @click="saveMarkedProjectGateDetails()"
    >
      Confirm
    </v-btn>
    <v-btn
      id="button-2"
      class="mt-2"
      color="#aaa"
      @click="cancelGoogleMapGeocoding()"
    >
      <span class="black--text">Cancel</span>
    </v-btn>
    <div
      id="map"
      style="height:80vh;"
    />
  </v-container>
</template>
<script>
  import { Loader } from '@googlemaps/js-api-loader';
  import Constants from 'src/constants';
  import { googleMapApiKey } from 'src/app-globals';

  export default {
    name: 'ProjectGateGeolocation',
    data () {
      return {
        map: null,
        geocoder: null,
        marker: null,
        google: null,
        searchText: '',
      };
    },
    computed: {
      isTextEntered () {
        if (this.searchText !== '') {
          return true;
        }
        return false;
      },
    },
    async mounted () {
      await this.loadMap();
    },
    methods: {
      loadMap () {
        const loader = new Loader({
          apiKey: googleMapApiKey,
          version: 'weekly',
          libraries: ['places'],
        });
        loader.load().then((google) => {
          this.map = new google.maps.Map(document.getElementById('map'), {
            center: { lat: -34.397, lng: 150.644 },
            zoom: 8,
            mapTypeControl: false,
          });
          this.geocoder = new google.maps.Geocoder();
          this.marker = new google.maps.Marker({
            map: this.map,
          });
          this.google = google;
          this.initAutoComplete();
        }).catch((e) => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Geocode was not successful for the following reason: ' + e,
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
        });
      },
      initAutoComplete () {
        const input = document.getElementById('pac-input');
        const autocomplete = new this.google.maps.places.Autocomplete(input);
        autocomplete.bindTo('bounds', this.map);
        autocomplete.addListener('place_changed', () => {
          this.marker.setVisible(false);
          const place = autocomplete.getPlace();
          if (!place.geometry) {
            this.$store.dispatch('alert/onAlert', {
              message: 'No details found for this location',
              type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
            return;
          }
          this.$store.dispatch('projectgates/storeMarkedProjectGateDetails', place);
          if (place.geometry.viewport) {
            this.map.fitBounds(place.geometry.viewport);
          } else {
            this.map.setCenter(place.geometry.location);
            this.map.setZoom(17);
          }
          this.marker.setPosition(place.geometry.location);
          this.marker.setVisible(true);
        });
      },
      clear () {
        document.getElementById('pac-input').value = '';
        this.searchText = '';
      },
      saveMarkedProjectGateDetails () {
        if (this.$route.name === 'Google Maps') {
          this.$router.push({ name: 'Add Project Gate' });
        } else {
          this.$router.push(`/project-gates/${this.$route.params.projectGateId}/edit`);
        }
      },
      cancelGoogleMapGeocoding () {
        if (this.$route.name === 'Google Maps') {
          this.$store.dispatch('projectgates/removeMarkedProjectGateDetails');
          this.$router.push({ name: 'Add Project Gate' });
        } else {
          this.$router.push(`/project-gates/${this.$route.params.projectGateId}/edit`);
        }
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
#pac-input {
  background-color: #ECEFF1;
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
  font-weight: 800;
  padding: 5px 10px 5px 10px;
  text-overflow:clip;
  width: 800px;
  border: 1px solid #37474F;
}
@media (max-width: 767px) {
  #pac-input {
    width: 350px;
  }
}
#map {
  margin-top: 10px;
}
#button-1 {
  margin-left: 25px;
  margin-bottom: 12px;
}
#button-2 {
  margin-bottom: 12px;
}
</style>
